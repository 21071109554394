@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.bannerSlider {
  width: 85.566%;
}

.achievers {
  width: 14.434%;
}

.for_only_Desk {
  display: block;
}

.catsub_mobile_version {
  display: none !important;
}

/* .header-nav .manmobile{
  display: none !important;
}
@media (max-width: 768px) {
  .header-nav .manmobile{
    display:flex;
  }
} */
.empty-state {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.empty-stateimg {
  height: 160px;
  width: 160px;
}

.noproductconta {
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.noproductconta .noproductImaage {
  width: 25%;
  height: 80%;
}

.menumainhomebar {
  position: relative;
}

.menumainhomebar .mobilemenubar {
  display: none;
  position: absolute;
}

.mobile_notification {
  display: none;
}

.header-nav,
.header-nav-middle {
  z-index: 99;
}

.shop-category-box1 {
  width: 170px;
  height: 68px;
  display: flex;
  background-color: #f8f8f8;
  padding: 8px;
  border-radius: 7px;
  justify-content: center;
  align-items: center;
}

.body {
  overflow: hidden;
}

/* .body .row .view .title-name,.price{
  font-weight: 550;
  font-size: 25px;
} */
.body .row .view .text {
  font-weight: 500;
}

.body .row .view .text-left {
  font-size: 15px;
  font-family: "Public Sans", sans-serif;
  line-height: 22px;
}

.body .row .view .brand-list h6 {
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
}

.load {
  display: flex;
  justify-content: center;
  align-items: center;
}

.clasman {
  text-decoration: none;
}

.homeSlider {
  /* width: 100%; */
}

/* .homeSlider .homeSliderImg {
	width: 100%;
} */

.homeSlider .homeSliderImg img {
  /* width: 100%;
	height: 100%; */
  cursor: pointer;
}

.swiperimg {
  border-radius: 10px;
  margin: 10px;
  width: 95%;
}

.mySwiper {
  margin: 0 20px 20px 20px;
}

.faicon {
  font-size: 25px;
  cursor: pointer;
}

.hovfaicon {
  font-size: 20px;
  cursor: pointer;
}

.myimgmy:hover img {
  cursor: pointer;
}

.secomain {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.secomain .secconta {
  width: 60%;
  height: 8vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.secconta ul {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2%;
  width: 100%;
}

.secconta ul li {
  list-style: none;
  color: #111;
  font-weight: 501;
  padding: 0 4vh;
}

.secconta ul li:hover {
  color: #0d6efd;
  transition: 0.5s;
}

.faicoarrow {
  font-size: 17px;
  margin-top: 2px;
}

.bottompadd {
  margin-bottom: 6vh;
}

.bottompadd1 {
  margin-bottom: 14vh;
}

.serchsugist {
  display: none;
}

.search-box .serchsugist {
  display: block;
  position: absolute;
  z-index: 9999;
  border: 1px solid #00b300;
  background-color: aliceblue;
  width: 41.1%;
  padding: 0.5%;
  border-radius: 4px;
}

.serchsugist h6:nth-child(2) {
  color: #20c997;
  font-weight: 502;
}

/* .serchsugist ul .suglink .liclas:nth-child(odd){
  background-color: Lightgreen;
} */
.serchsugist ul .suglink .liclas:hover {
  background-color: Lightgreen;
}

/* ===============SelectCatSlider start ============== */
.blur-up.lazyloaded {
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-bottom: 15px; */
}

footer .main-footer li {
  cursor: pointer;
}

footer .left-footer .text {
  color: #4889be;
}

.order-unsuccess {
  display: flex;
  justify-content: center;
  align-items: center;
}

.order-unsuccess .UnsuccessImg {
  width: 50%;
}

.order-unsuccess .UnsuccessImg img {
  width: 20%;
}

/* .unsuccess h3,h5,h6{
  color: red;
} */
.pending h3 {
  font-size: 42px;
  font-family: "Times New Roman", Times, serif;
}

.updateprotext {
  padding: 10px;
  background-color: #009933;
  border: none;
  outline: none;
  color: #fff;
  font-family: "Poppins";
}

.pending h5 {
  font-size: 20px;
  padding: 10px;
  font-family: "Times New Roman", Times, serif;
}

.pending h6 {
  font-size: 15px;
  font-family: "Times New Roman", Times, serif;
}

.optiocheckbox {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.agedivigm {
  margin-left: 40%;
  text-align: center;
}

.agedivigm .agentimg {
  width: 30vh;
  background: #f8f9fa;
  height: 30vh;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.agentimg img {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
}

.ageidbox {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #f4f6f9;
}

.aguserid {
  background: #00bf72;
  height: 7vh;
  border-radius: 4px;
}

.swiper-pagination-bullet-active {
  background-color: #00bf72 !important;
}

.otp_for_mobile {
  display: none !important;
}

/* ===============SelectCatSlider end ============== */

.mobileCart {
  display: none;
}

.MainofSplashScreen {
  display: none !important;
}

/* here i make the changes */
.only_in_mobile_version {
  display: none !important;
}

.only_in-desktop_description {
  display: block !important;
}

/* to here */

/* no product */
/* .noproductcontas {
  min-height: 100vh;
  width: 100%;
  background-color: blue;
} */

/* endd */
.noproductcontas {
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.full-height-offcanvas .offcanvas-body {
  height: 100vh; /* 100% of the viewport height */
  display: flex;
  flex-direction: column;
}

.full-height-offcanvas {
  height: 100vh !important; /* Make the Offcanvas full height */
  max-height: 100vh; /* Ensure it doesn't exceed the viewport height */
}
@media screen and (max-width: 768px) {
  .to_Add_addressss_conta {
    bottom: 3.1rem;
    position: fixed;
    height: 4.3rem;
    width: 90%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .delivery_ttooo_heree {
    width: 100%;
    height: 2.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .deliveryyy_contenttt {
    width: 95%;
    height: 90%;
    font-size: 0.9rem;
    font-weight: bold;
    background-color: #0da487;
    color: #fff;
    border-radius: 0.6rem;
    border: 1px solid #0da487;
  }

  .to_Add_new_Address {
    height: 3rem;
    width: 100%;
    border: 1px solid #00ab66;
    border-radius: 10px;
    color: #fff;
    font-size: 1.1rem;
    display: flex;
    gap: 0.2rem;
    align-items: center;
    justify-content: center;
    color: #00ab66;
    font-weight: 600;
  }

  .icon_twoooo {
    display: flex;
    gap: 6px;
  }

  .dellllicon {
    color: #ff0000;
    font-size: 0.5rem;
  }

  .edittticonnn {
    color: #009933;
    font-size: 0.5rem;
  }

  .delllll_circlee {
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ff0000;
  }

  .edddit_circleee {
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #009933;
  }

  .delllll_circlee {
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ff0000;
  }

  /* here i make the changes */
  .only_in_mobile_version {
    display: block !important;
  }

  .only_in-desktop_description {
    display: none !important;
  }

  .normal_contenttttt {
    color: #6c757d;
    font-weight: 600;
    font-size: 0.8rem;
    text-decoration-line: line-through;
  }

  /* .content_change_the_box {
    height: 2rem;
    width: 4rem;
    color: #000 !important;
    background-color: red;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .content_change_the_box.active {
    height: 2rem;
    width: 4rem;
    color: #ffff !important;
    background-color: #009933;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  } */

  .content_change_the_box a {
    background-color: transparent;
    color: #000 !important;
    border: 2px solid #ececec;
    height: 2rem;
    width: 4rem;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .content_change_the_box a.active {
    background-color: #00ab60;
    color: #fff !important;
    border: none;
  }

  /* 
  #color_to_changfe {
    color: #ffff !important;
    font-weight: bold;
  } */

  .select-packegexxxx {
    width: 100%;
    display: flex;
    gap: 5px;
    height: 2.3rem;
    align-items: center;
  }

  /* to here */
  .MainofSplashScreen {
    display: block !important;
  }

  .login_for_only_desktop {
    display: none;
  }

  .mobile_nonnnne {
    display: none !important;
  }

  .otp_for_mobile {
    display: block !important;
  }

  .for_otp_for-only_desk {
    display: none !important;
  }

  .modileee_padding_none {
    display: none !important;
  }

  .footermobile {
    display: none !important;
  }

  .containerrrrrrr_none_in_mobileee {
    display: none !important;
  }

  .for_only_Desk {
    display: none;
  }

  .bottompadd {
    margin-bottom: 12vh;
  }

  .search-box .serchsugist {
    width: 83%;
  }

  .noproductcontas {
    width: 100%;
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .noproductcontas .noproductImaage {
    width: 70%;
    height: 70%;
  }

  /* Nishitha.......Product description......🤞 */

  .dicount_offff {
    width: 5rem;
    height: 2.5rem;
    background-color: #00ab66;
    color: #fff;
    font-size: 0.8rem;
    display: flex;
    top: 120px;
    left: 20px;
    position: absolute;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    border: 1px solid #00ab66;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .total_Descrription_containerrr {
    margin-top: 1rem;
    width: 100%;
  }

  .product-nameeeee {
    font-size: 2.3rem;
    color: #000;
    font-family: "Poppins";
  }

  .product_similar_pro {
    display: flex;
    align-items: center;
    height: 1.8rem;
    color: #7f00ff;
    font-weight: bold;
  }

  .colorrrr_boldddd {
    display: flex;
    align-items: center;
    height: 1.8rem;
    color: #7f00ff;
    font-weight: bold;
  }

  .description_totallll {
    padding-top: 0.3rem;
    height: 7rem;
    width: 100%;
  }

  .pack_quan_tyyyyyyy {
    color: #000;
    font-weight: bold;
    font-size: 0.9rem;
  }

  .container_to_flexxxxx {
    display: flex;
    width: "100%";
    height: 3rem;
    align-items: center;
    justify-content: space-between;
  }

  .container_width_flex_cc {
    width: 80%;
    height: 100%;
    display: flex;
    height: 1.9rem;
    /* background-color: red; */
    align-items: center;
    justify-content: space-between;
  }

  .pice_bold_Contenttt {
    color: #000;
    font-size: 1.1rem;
    font-weight: bold;
  }

  .to_off_disss {
    height: 1.3rem;
    width: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    font-size: 0.8rem;
    font-weight: bold;
    /* background-color: #7f00ff; */
    border-radius: 3px;
  }

  .btn_tio_adddd {
    width: 100%;
    height: 3rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .btn_inside_addd {
    width: 6rem;
    height: 2.5rem;
    background-color: #00ab66;
    border: 1px solid #00ab66;
    border-radius: 5px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 1.1rem;
  }

  .for_incrementinggg {
    width: 6rem;
    height: 2.5rem;
    background-color: #e6fff2;
    border: 1px solid #00ab66;
    border-radius: 5px;
    color: #00ab66;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  .for_decccc {
    background-color: #e6fff2;
    border: none;
    color: #00ab66;
  }

  .for_increme {
    background-color: #e6fff2;
    border: none;
    color: #00ab66;
  }

  /* end */

  /* Notification......Nishitha .....🤞 */

  .mobile_notification {
    display: block;
  }

  .headerrrr_notificatin {
    height: 3.5rem;
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 0.8rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #fff;
  }

  .center_notifi {
    width: 100%;
    height: 36rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .plusssss_btn {
    background-color: #00ab66;
    border: 1px solid #00ab66;
    color: #ffff;
    font-size: 0.7rem;
  }

  .minussss_btn {
    background-color: #00ab66;
    border: 1px solid #00ab66;
    color: #ffff;
    font-size: 0.7rem;
  }
:where(.css-dev-only-do-not-override-5wsri9).ant-picker-outlined{
  margin-top: 7px;
  background-color: #fff;
  /* border-style: solid;
  border-color: #ccc; */
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
  .sub_alert {
    height: 6rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .img_noti {
    height: 150px;
    width: 150px;
  }

  .existing {
    color: black;
    font-weight: 300;
    font-size: 0.8rem;
    letter-spacing: 0.05rem;
  }
}

@media (max-width: 1199px) {
  header .top-nav .navbar-top .mobile .search-box {
    width: 100%;
  }

  .search-box .serchsugist {
    width: 60%;
  }

  /* header .top-nav .navbar-top .dextop .search-box .input-group{
      display: flex;
      flex-wrap: nowrap;
  } */
}

@media (max-width: 1080px) {
  .header-nav .manmobile {
    display: none !important;
  }

  .search-box .serchsugist {
    width: 57%;
  }
}

@media (max-width: 1030px) {
  .search-box .serchsugist {
    width: 60%;
  }
}

@media (max-width: 991px) {
  .search-box .serchsugist {
    width: 83%;
  }
}

@media (max-width: 920px) {
  .search-box .serchsugist {
    width: 67%;
  }
}

@media (max-width: 767px) {
  header .top-nav .navbar-top .mobile {
    width: 100%;
  }

  .search-box .serchsugist {
    width: 83%;
  }
}

@media (max-width: 769px) {
  .header-nav .manmobile {
    display: block !important;
  }

  .menuoffdrop {
    position: relative;
  }

  .menuoffdrop .menulog {
    position: absolute;
    bottom: 10;
    left: 10;
  }

  .menuoffdrop .menulog li {
    color: #4a5568;
    font-weight: 700;
  }

  .search-box .serchsugist {
    width: 78.7%;
  }

  .laptopversioncart {
    display: none;
  }

  .mobileCart {
    display: block;
    height: 23vh;
    width: 100%;
    margin: 1vh 0;
    /* border:1px solid #000; */
    /* box-shadow: 1px 1px 1px .5px gray; */
    border-radius: 5px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }

  .MiddleCart {
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* flex-direction: row; */
    height: 50%;
    width: 100%;
    /* background: #e6e6e6; */
    padding: 5px;
  }

  .cartcontainermain {
    width: 95%;
    height: 130px;
    background-color: #fff;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
    margin: 10px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  }

  .ImageContainer {
    margin-top: 50px;
    height: 100%;
    width: 50%;
    /* background: red; */
  }

  .detailContainer {
    height: 100%;
    width: 50%;
    margin-left: 5%;
    /* background: green; */
  }

  .productName {
    font-weight: bold;
    /* padding: 5px; */
  }

  .subtitle {
    color: #0da487;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .button1 {
    background-color: #e3e0e0;
    border-radius: 100%;
    /* border-color: white; */
    height: 30px;
    width: 30px;
    /* padding-right: 20px; */
    display: flex;
    justify-content: center;
    align-items: center;
    outline: inherit;
    border: none;
  }

  .middlepart {
    border-color: white;
    background-color: white;
    height: 30px;
    width: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .CartRight {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .quantity {
    display: flex;
    flex-direction: row;
    width: 150px;
  }

  .price {
    margin-top: 5px;
    display: flex;
    /* justify-content: space-between; */
    justify-content: start;
    align-items: center;
  }

  .remove1 {
    color: red;
    margin-left: 10px;
    font-size: 25px;
  }

  .PriceTitle {
    font-size: 16px;
  }

  .priceAmount {
    font-size: 16px;
    color: black;
    font-weight: 600;
  }

  .DisAmount {
    font-size: 12px;
    color: #111;
    padding-left: 5px;
  }

  .ImageContainer {
    /* width: 100%;
    height: 100%; */
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center;
    text-align: center; */
  }

  .ImageContainer .imagecart {
    width: 100%;
    height: 100px;
  }

  /* display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  color: #4a5568;
  font-weight: 700 */
}

@media screen and (max-width: 600px) {
  .secomain .secconta {
    width: 100%;
    height: 5vh;
  }

  .search-box .serchsugist {
    width: 83.7%;
  }

  .secconta ul {
    width: 70%;
    justify-content: space-between;
    margin-left: -8%;
    margin-top: 4%;
  }

  .secconta ul li {
    padding: 0;
    font-size: 13px;
  }

  .footermobile {
    display: none !important;
  }
}

.customeButton {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 7px 11px;
  background-color: #0e947a;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.checkbox-wrapper-6 .tgl {
  display: none;
}

.checkbox-wrapper-6 .tgl,
.checkbox-wrapper-6 .tgl:after,
.checkbox-wrapper-6 .tgl:before,
.checkbox-wrapper-6 .tgl *,
.checkbox-wrapper-6 .tgl *:after,
.checkbox-wrapper-6 .tgl *:before,
.checkbox-wrapper-6 .tgl + .tgl-btn {
  box-sizing: border-box;
}

.checkbox-wrapper-6 .tgl::-moz-selection,
.checkbox-wrapper-6 .tgl:after::-moz-selection,
.checkbox-wrapper-6 .tgl:before::-moz-selection,
.checkbox-wrapper-6 .tgl *::-moz-selection,
.checkbox-wrapper-6 .tgl *:after::-moz-selection,
.checkbox-wrapper-6 .tgl *:before::-moz-selection,
.checkbox-wrapper-6 .tgl + .tgl-btn::-moz-selection,
.checkbox-wrapper-6 .tgl::selection,
.checkbox-wrapper-6 .tgl:after::selection,
.checkbox-wrapper-6 .tgl:before::selection,
.checkbox-wrapper-6 .tgl *::selection,
.checkbox-wrapper-6 .tgl *:after::selection,
.checkbox-wrapper-6 .tgl *:before::selection,
.checkbox-wrapper-6 .tgl + .tgl-btn::selection {
  background: none;
}

.checkbox-wrapper-6 .tgl + .tgl-btn {
  outline: 0;
  display: block;
  width: 4em;
  height: 2em;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox-wrapper-6 .tgl + .tgl-btn:after,
.checkbox-wrapper-6 .tgl + .tgl-btn:before {
  position: relative;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
}

.checkbox-wrapper-6 .tgl + .tgl-btn:after {
  left: 0;
}

.checkbox-wrapper-6 .tgl + .tgl-btn:before {
  display: none;
}

.checkbox-wrapper-6 .tgl:checked + .tgl-btn:after {
  left: 50%;
}

.checkbox-wrapper-6 .tgl-light + .tgl-btn {
  background: #6c757d;
  border-radius: 2em;
  padding: 2px;
  transition: all 0.4s ease;
  font-size: 10px;
}

.checkbox-wrapper-6 .tgl-light + .tgl-btn:after {
  border-radius: 50%;
  background: #fff;
  transition: all 0.2s ease;
}

.checkbox-wrapper-6 .tgl-light:checked + .tgl-btn {
  background: #0da487;
}

.loadingMain {
  width: 100%;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cssmarquee {
  height: 30px;
  /* overflow: hidden; */
  /* position: relative; */
}

.cssmarquee h1 {
  font-size: 2em;
  color: turquoise;
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  line-height: 30px;
  text-align: center;
  transform: translateX(100%);
  animation: cssmarquee 15s linear infinite;
}
@keyframes cssmarquee {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

@media (max-width: 550px) {
  .search-box .serchsugist {
    width: 100%;
  }

  .ImageContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .ImageContainer .imagecart {
    width: 40%;
  }
}

@media (max-width: 450px) {
  .search-box .serchsugist {
    width: 92%;
  }

  .remove1 {
    color: red;
    margin-left: 10px;
    font-size: 25px;
  }

  .PriceTitle {
    font-size: 16px;
  }

  .priceAmount {
    font-size: 19px;
    color: black;
  }

  .DisAmount {
    font-size: 16px;
    color: #111;
  }

  .ImageContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .ImageContainer .imagecart {
    width: 50%;
  }
}

@media (max-width: 376px) {
  .search-box .serchsugist {
    width: 100%;
  }

  .ImageContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .ImageContainer .imagecart {
    width: 60%;
  }
}

.removeintab {
  display: none;
}

.logbtncontainer {
  border-top: 0.4px solid #ccc;
  border-bottom: 0.4px solid #ccc;
  margin-top: 10px;
}

.logouttext {
  font-family: "Poppins";
}

@media screen and (orientation: portrait) {
  .removeintab {
    padding: 10px;
  }

  .removeintab {
    display: block;
    position: relative;
  }

  .removeinsm {
    display: none;
  }

  .mvProfile {
    height: 100px;
    width: 100px;
    border-radius: 50%;
  }

  .order {
    display: flex;
    justify-content: space-between;
    padding: 15px;
  }
}

.swipermaincontainer {
  background-color: "#fff";
  padding: 8px;
  /* box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px; */
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  /* width: 97%; */
}

.swipersubcontainer {
  display: flex;
  align-items: center;
  border-bottom: 1px dashed #3c006b;
  padding: 8px;
}

.swipersubcontainer1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 3px;
  border-radius: 5px;
}

.swipersubcontainer2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}

.swipersubcontainer3 {
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swipersubcontainer4 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;

  padding: 10px;
}

#lorel {
  background-color: #00ab66;
  position: absolute;
  font-weight: bold;
  width: 70%;
  border-bottom-right-radius: 18px;
  text-align: center;
  padding: 5px;
  color: #fff;
  left: -10px;
  top: -11px;
  padding-top: 12px;
}

#lorel {
  display: none;
}

#lorel1 {
  background-color: #1d8054;
  position: absolute;
  font-weight: bold;
  width: 70%;
  border-bottom-right-radius: 18px;
  border-top-right-radius: 18px;
  text-align: center;
  padding: 6px;
  color: #fff;
  left: -11px;
  top: -11px;
  padding-top: 6px;
}

.offertext {
  font-size: 26px;
}

.currentcontainer {
  margin-top: 8px;
}

.buttontext {
  padding: 10px;
  border: none;
  outline: none;
}

.sliderbtn {
  border: 1px solid #00ab66;
  height: 30px;
  padding: 3px;
  border-radius: 5px;
  width: 63px;
  background-color: #00ab66;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sliderlastbtn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sliderlastbtn1 {
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
}

.footermaincontainer {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 15px;
}

.footercontainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subfootercontainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.subfootercontainer1 {
  display: flex;
  justify-content: flex-start;
}

.textfieldcontainer {
  margin-bottom: 8px;
}

.textfield {
  width: 100%;
}

.textfield .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-radius: 10px;
  border-color: #0e947a;
  border-width: 0.3px;
  /* border-bottom-color:  #0e947a; */
}

.textfield .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #0e947a;
  border-radius: 10px;
  border-width: 0.3px;
  /* border-bottom-color:  #0e947a; */
}

.textfield .MuiInputLabel-root.Mui-focused {
  color: #0e947a;
}

.login-image {
  display: none;
}

@media screen and (max-width: 576px) {
  .login-image {
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* .login-search{
  display: none;
} */
}

@media screen and (max-width: 768px) {
  .login-image {
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .offertext {
    font-size: 12px;
  }

  #lorel1 {
    display: none;
  }

  #lorel {
    display: block;
  }

  .amounthcontainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .nitamtext {
    font-size: 5px;
  }

  .empty-state {
    margin-top: 25px;
  }

  .updateprocontainer {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    margin-top: 15px;
    background-color: #009933;
    width: 97%;
    margin-left: 17px;
  }

  .updateprocontainer1 {
    display: flex;
    justify-content: center;
  }

  .updateprotext {
    padding: 10px;
    background-color: #009933;
    border: none;
    outline: none;
    color: #fff;
    font-family: "Poppins";
  }
}

.Orderbody {
  display: none;
}

@media screen and (orientation: portrait) {
  .Orderbody {
    display: block;
  }

  tr {
    padding: 0px;
  }
}

@media screen and (max-width: 780px) {
  .mobile-versionnnn-modal .modal-content {
    animation: slideUpFromBottom 0.5s ease-out !important;
    position: fixed !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    background-color: #fff !important;
    border-radius: 10px !important;
    overflow-y: auto !important;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }

  @keyframes slideUpFromBottom {
    from {
      transform: translateY(100%);
    }

    to {
      transform: translateY(0);
    }
  }
}

.offcanvas-backdrop.show{
  opacity: 0 !important;
}
