* {
  padding: 0;
  margin: 0;
}

@media screen and (min-width: 280px) and (max-width: 768px) {
  .MainofSplashScreen {
    top: 0px;
    height: 100vh;
    width: 100%;
    /* background-image: url(../../assets/Images/BackgroundImage6.jpg); */
    background-image: url(../../../public/assets/images/loginbgc.jpeg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.8rem;
    gap: 1rem;
  }

  /* .numberr::placeholder{
    color:#808080;
  } */
  .SubpartOfSplash {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    width: 100%;
    /* border:2px solid red; */
    height: 100%;
  }

  .logoContainer {
    height: 10rem;
    width: 8rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .logoImgg {
    height: 3rem;
    width: 7rem;
    /* border: 1px solid red; */
  }
  .titlee {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .phonenumberpart {
    border: 1px solid #fff;
    width: 96%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.4rem;
    border-radius: 0.5rem;
  }
  .indianNum {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid;
    padding: 0.4rem;
    border-bottom: none;
    border-top: none;
    border-left: none;
    border-color: #fff;
  }
  .Inputpart {
    /* border: 1px solid #000; */
    height: 3rem;
    border-radius: 0.6rem;
    width: 100%;
    border: none;
    background-color:transparent;
  }
  .indiaaa {
    font-size: 0.9rem;
    font-weight: 700;
  }
  .flagImgg {
    width: 2rem;
  }
  .numberr {
    width: 100%;
    height: 100%;
    border-radius: 0.6rem;
    background-color: transparent;
    border: none;
  }
  .numberr::placeholder {
    padding-left: 0.5rem;
    color: #e9fcf4;
    font-size: 0.9rem;
    font-weight: 500;
    align-items: center;
    background-color: transparent;
  }
  /* .enternumber{
        border: 1px solid green;
     } */
  .enternumber > h5 {
    font-size: 2rem;
    color: #fff;
    font-weight: bold;
    align-items: center;
  }
  .enternumber > span {
    font-size: 1rem;
    color: #fff;
    font-weight: 600;
    text-align: center;
  }
  .downPartofSplash {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 85%;
    /* justify-content: center; */
    border-radius: 1.3rem;
    background-color: #1bb876;
    border-color: #d3f8e9;
  }
  .downPartofSplash > button {
    padding: 0.7rem;
    background-color: transparent;
    border: none;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    font-weight: bold;
    color: #fff;
    font-size: 0.9rem;
  }
  .accpetingText {
    font-size: 0.65rem;
    font-weight: 500;
    color: #fff;
  }
  .colorrr {
    color: #cc0052;
  }
}
